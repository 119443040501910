import React from 'react'
import PythonC from '../assets/PythonC.jpg'
import DjangoC from '../assets/DjangoC.jpg'
import SqlC from '../assets/SqlC.jpg'
const Courses = () => {
  return (
    <div name='courses' className='w-full md:h-screen text-gray-300 bg-[#0a192f]'>
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600'>Courses</p>
                <p className='py-6'>These are the official courses I've finished.</p>
            </div>
            {/* grid container */}
            <div  className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
                {/* grid item */}
                <div style={{backgroundImage: `url(${PythonC})`}}
                 className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>

                    {/* hover effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                        Python Course.
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://certificate.algoexpert.io/ProgrammingExpert%20Certificate%20PE-012ae7b8fc" target="_blank">
                                <button className='text-center rounded-lg px-4 py-3 bg-white text-gray-700 font-bold text-lg'>Certificate</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{backgroundImage: `url(${DjangoC})`}}
                 className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
                    {/* hover effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                        Django Course.
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://www.udemy.com/certificate/UC-c9732367-57d3-4464-8d6a-d9d548fe3a8a/" target="_blank">
                                <button className='text-center rounded-lg px-4 py-3 bg-white text-gray-700 font-bold text-lg'>Certificate</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{backgroundImage: `url(${SqlC})`}}
                 className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>

                    {/* hover effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider'>
                        SQL Course.
                        </span>
                        <div className='pt-8 text-center'>
                            <a href="https://www.udemy.com/certificate/UC-82ac5b27-0ba0-44ea-9ac9-5b4d3c542dd5/" target="_blank">
                                <button className='text-center rounded-lg px-4 py-3 bg-white text-gray-700 font-bold text-lg'>Certificate</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Courses