import React from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi'
import {Link} from 'react-scroll'

const Home = () => {
  return (
    <div name='home' className='w-full h-screen bg-[#0a192f]'>
        {/* container */}
        <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
            <p className='text-pink-600'>Hi, my name is</p>
            <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>Jose Manuel Casados Calles</h1>
            <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>I am a new junior developer trying to be a full stack.</h2>
            <p className='text-[#8892b0] py-4 max-w-[700px]'>I am a junior developer with knowledge of HTML, CSS, JavaScript, React, Python, Django, etc. I am looking forward to working with you.</p>
            <div>
                <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 duration-300 hover:border-pink-600 '>
                <Link to="work" smooth={true}  duration={500}>
                View Work
                </Link>
                    <span className='group-hover:rotate-90 duration-300'>
                    <Link to="work" smooth={true}  duration={500}>
                      <HiArrowNarrowRight className='ml-3'/> 
                    </Link>                         
                    </span>
                </button>
            </div>
        </div>
        
    </div>
  )
}

export default Home