import React, {useState} from 'react'
import Logo from '../assets/logojm.png'
import {FaBars, FaTimes, FaGithub, FaLinkedin} from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import {Link} from 'react-scroll'

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)

  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300'>
        <div>
            <img src={Logo} alt="Logo image" style={{width: '50px'}} />
        </div>
        
        {/* menu */}
        
            <ul className='hidden md:flex'>
                <li className='hover:scale-110 duration-500 hover:text-pink-400'>
                <Link to="home" smooth={true} duration={500}>
                Home
                </Link>
                </li>
                <li className='hover:scale-110 duration-500 hover:text-pink-400'>
                <Link to="about" smooth={true}  duration={500}>
                About
                </Link>
                </li>
                <li className='hover:scale-110 duration-500 hover:text-pink-400'>
                <Link to="skills" smooth={true}  duration={500}>
                Skills
                </Link>
                </li>
                <li className='hover:scale-110 duration-500 hover:text-pink-400'>
                <Link to="courses" smooth={true}  duration={500}>
                Courses
                </Link>
                </li>
                <li className='hover:scale-110 duration-500 hover:text-pink-400'>
                <Link to="work" smooth={true}  duration={500}>
                Work
                </Link>
                </li>
                <li className='hover:scale-110 duration-500 hover:text-pink-400'>
                <Link to="contact" smooth={true}  duration={500}>
                Contact
                </Link>
                </li>
            </ul>        

        {/* hamburger */}
        <div onClick={handleClick} className='md:hidden fixed w-full h-[80px] flex justify-end pt-8 px-9 z-10'>
            {!nav ? <FaBars/> : <FaTimes/>}
        </div>
        {/* mobile menu */}
        <ul className= {!nav ? 'translate-x-full' : 'absolute top-0  left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center ease-in-out duration-300'} >
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="home" smooth={true} duration={500}>
                Home
                </Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="about" smooth={true}  duration={500}>
                About
                </Link></li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="skills" smooth={true}  duration={500}>
                Skills
                </Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="courses" smooth={true}  duration={500}>
                Courses
                </Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="work" smooth={true}  duration={500}>
                Work
                </Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="contact" smooth={true}  duration={500}>
                Contact
                </Link>
            </li>
        </ul>
        {/* social icons */}
        <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
        <ul>
            <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-500 bg-blue-600'>
                <a className='flex justify-between items-center w-full text-gray-300' href="https://www.linkedin.com/in/jose-manuel-casados-calles-9a8b27204?original_referer=" target="_blank" rel="noopener noreferrer">
                    Linkedin <FaLinkedin size={30} />
                </a>
            </li>
            <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-500 bg-[#333333]'>
                <a className='flex justify-between items-center w-full text-gray-300' href="https://github.com/JMCasados" target="_blank" rel="noopener noreferrer">
                    Github <FaGithub size={30} />
                </a>
            </li>
            <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-500 bg-cyan-600'>
                <a className='w-full' href= "/">
                
                    <Link to="contact" smooth={true}  duration={500} className='flex justify-between items-center text-gray-300' >
                    Email <HiOutlineMail size={30} />
                    </Link>
                    
                </a>
            </li>
            {/* <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-500 bg-gray-600'>
                <a className='flex justify-between items-center w-full text-gray-300' href="/">
                    Resume <BsFillPersonLinesFill size={30} />
                </a>
            </li> */}
        </ul>
        </div>

    </div>
  )
}

export default Navbar


